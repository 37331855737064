import { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Typography } from '@gbm/starman-next/typography';

type MenuItemProps = {
  children: ReactNode;
};

export function MenuItem({
  children,
  to,
  ...rest
}: MenuItemProps & Omit<NavLinkProps, 'children'>) {
  return (
    <NavLink
      className={({ isActive }) => {
        const activeStyle = isActive ? 'text-primary' : 'text-muted-foreground';
        return `flex items-center gap-3 rounded-lg px-3 py-2  transition-all hover:text-primary ${activeStyle}`;
      }}
      to={to}
      {...rest}
    >
      <Typography variant="body2">{children}</Typography>
    </NavLink>
  );
}
