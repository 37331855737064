import * as ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Toaster } from '@app-evolution-cms/shadcn-ui';
import { DEFAULT_LANGUAGE, IntlProvider } from '@gbm/snacks-i18n';

import App from './app/app';
import { config } from './config';
import { translationsJson } from './translations';

import './styles.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity,
    },
  },
});

const { IS_DEV_ENV } = config();

// Start mock API if app will work offline
// https://mswjs.io/docs/getting-started/integrate/browser
async function prepare() {
  if (import.meta.env.VITE_OFFLINE) {
    const apiMock = (await import('./__mocks__/api/browser')).default;

    return apiMock.start({ onUnhandledRequest: 'bypass' });
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
prepare().then(() => {
  root.render(
    <IntlProvider lang={DEFAULT_LANGUAGE} translations={translationsJson}>
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster closeButton duration={5000} />
        {IS_DEV_ENV ? <ReactQueryDevtools position="bottom-right" /> : null}
      </QueryClientProvider>
    </IntlProvider>,
  );
});
