/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConvertedToObjectType, TranslationJsonType } from './types';

/**
 * This file is seperate from the './i18n.ts' simply to make the Hot Module Replacement work seamlessly.
 * Your components can import this file in 'messages.ts' files which would ruin the HMR if this isn't a separate module
 */
export const translations: ConvertedToObjectType<TranslationJsonType> =
  {} as any;
/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still having the intellisense support
 * along with type-safety
 */
export const convertLanguageJsonToObject = (
  json: any,
  // eslint-disable-next-line default-param-last
  objToConvertTo = translations,
  current?: string,
  first?: boolean,
) => {
  Object.keys(json).forEach((key) => {
    const separator = first ? '' : '.';
    const currentLookupKey = current
      ? `${current}${separator}${key}`
      : `${key}.`;
    const newObjToConvertTo = objToConvertTo;
    if (typeof json[key] === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Here complains that key is string, but it's fine
      newObjToConvertTo[key] = {};
      convertLanguageJsonToObject(
        json[key],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Here complains that key is string, but it's fine
        objToConvertTo[key],
        currentLookupKey,
        !current,
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Here complains that key is string, but it's fine
      newObjToConvertTo[key] = currentLookupKey;
    }
  });
};
