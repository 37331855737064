import { Outlet } from 'react-router-dom';

import { NavBar } from './nav-bar';

import styles from './navigation.module.scss';

export function Navigation() {
  return (
    <div className={styles.navigation}>
      <NavBar />
      <main className={styles['navigation--child-wrapper']}>
        <Outlet />
      </main>
    </div>
  );
}
