import { JwtPayload, jwtDecode } from 'jwt-decode';
import isBefore from 'date-fns/isBefore';
import { utcToZonedTime } from 'date-fns-tz';

import { TIMEZONES } from '@gbm/utils';

import { config } from '../../config';
import { AuthTokensResponse } from '../types/api/auth';
import { refreshToken } from '../api/auth';

export function isValidToken(token: string) {
  if (!token || token === '') return false;

  const tokenExpirationDate = getTokenExpirationDate();
  const localDate = utcToZonedTime(new Date(), TIMEZONES.America_MexicoCity);

  return isBefore(localDate, tokenExpirationDate);
}

export function getTokens(): AuthTokensResponse {
  const { LOCAL_STORAGE } = config();
  const tokens = localStorage.getItem(LOCAL_STORAGE.AUTH);

  if (tokens) {
    return JSON.parse(tokens ?? '{}');
  }

  return {} as AuthTokensResponse;
}

export function getTokenExpirationDate() {
  const tokens = getTokens();

  return utcToZonedTime(
    new Date(Number(jwtDecode<JwtPayload>(tokens.accessToken).exp) * 1000),
    TIMEZONES.America_MexicoCity,
  );
}

export function getTokenRemainingTime(
  {
    inMinutes,
  }: {
    inMinutes?: boolean;
  } = { inMinutes: false },
) {
  const seconds = (+getTokenExpirationDate() - +new Date()) / 1000;

  if (inMinutes) return seconds / 60;

  return seconds;
}

export function saveTokens(tokens: AuthTokensResponse) {
  const { LOCAL_STORAGE } = config();

  localStorage.setItem(LOCAL_STORAGE.AUTH, JSON.stringify(tokens));
}

export function removeTokens() {
  const { LOCAL_STORAGE } = config();

  localStorage.removeItem(LOCAL_STORAGE.AUTH);
}

export const AUTH_BROADCASTER_ACTIONS = {
  errorSessionRefresh: 'error_session_refresh',
  logout: 'logout',
  sessionRefreshed: 'session_refreshed',
  unauthorized: 'unauthorized',
} as const;

export const handleLogin = () => {
  const { AUTH } = config();
  const loginUrl = new URL(
    `${AUTH.LOGIN_URL}${AUTH.PATHS.LOGIN}?client_id=${AUTH.CLIENT_ID}&${AUTH.QUERY_PARAMS.LOGIN}${AUTH.LOGIN_REDIRECT_URL}`,
  );

  removeTokens();
  window.location.replace(loginUrl);
};

export const handleLogout = () => {
  const { AUTH } = config();
  const logoutUrl = new URL(
    `${AUTH.LOGIN_URL}${AUTH.PATHS.LOGOUT}?client_id=${AUTH.CLIENT_ID}&${AUTH.QUERY_PARAMS.LOGIN}${AUTH.LOGIN_REDIRECT_URL}`,
  );

  removeTokens();
  window.location.replace(logoutUrl);
};

export const authBroadcaster = new BroadcastChannel('Auth');

export function notifyLogout() {
  authBroadcaster.postMessage({
    action: AUTH_BROADCASTER_ACTIONS.logout,
  });
}

export async function refreshSession() {
  const tokens = getTokens();

  if (!tokens.refreshToken) {
    notifyLogout();
    handleLogout();
    return false;
  }

  try {
    const refreshedTokens = await refreshToken({
      refreshToken: tokens.refreshToken,
    });
    saveTokens(refreshedTokens as AuthTokensResponse);

    return true;
  } catch {
    notifyLogout();
    handleLogout();

    return false;
  }
}
