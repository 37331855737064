import { RouteObject, Outlet } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const HELP_CENTER_ROUTES: RouteObject = {
  path: APP_ROUTES.helpCenter.main,
  element: <Outlet />,
  children: [
    {
      index: true,
      async lazy() {
        const { Main } = await import('./main');
        return { Component: Main };
      },
    },
  ],
};
