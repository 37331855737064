import {
  FormatDateOptions,
  FormatTimeOptions,
  formatDate as formatDateJS,
  formatTime,
} from '@gbm/utils';

type Options = {
  withTime?: boolean;
} & Partial<FormatDateOptions> &
  Partial<FormatTimeOptions>;

const DEFAULTS: Options = {
  withTime: true,
  useSystemsTimezone: true,
};

export function formatDate(date: string | number | Date, options?: Options) {
  const { withTime, ...formatOptions } = { ...DEFAULTS, ...options };

  if (!withTime) {
    return formatDateJS(date, formatOptions);
  }

  return `${formatDateJS(date, formatOptions)} - ${formatTime(
    date,
    formatOptions,
  )}`;
}

export function formatMillisecondsToTime(millisec: number) {
  let seconds = Math.ceil(millisec / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours: string | number;

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
  }

  seconds = Math.floor(seconds % 60);

  const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedMinutes}:${formattedSeconds}`;
}
