import camelcaseKeys from 'camelcase-keys';
import qs from 'qs';

import { HttpStatusCodes } from '@gbm/snacks-types';

import {
  getTokenRemainingTime,
  getTokens,
  handleLogout,
  notifyLogout,
  refreshSession,
} from './auth';

function parseJSON(response: Response, camelCaseConvertion: boolean) {
  if (
    response.status === HttpStatusCodes.NoContent ||
    response.status === HttpStatusCodes.ResetContent
  ) {
    return null;
  }

  if (isJsonResponse(response)) {
    return response.json().then((json) => {
      if (camelCaseConvertion) {
        return camelcaseKeys(json, { deep: true });
      }

      return json;
    });
  }

  return response.text();
}

function isJsonResponse(response: Response) {
  const contentType = response.headers.get('content-type');

  return (
    contentType &&
    (contentType.includes('application/json') ||
      contentType.includes('application/hal+json'))
  );
}

interface Request {
  url: string;
  options: Omit<RequestInit, 'body'> & {
    body?: Record<string, unknown> | string;
  };
  auth?: boolean;
  camelCaseConvertion?: boolean;
}

export interface NetworkError extends Error {
  response: Response;
  status: number;
}

export default async function request<T>({
  url,
  options = {},
  auth = true,
  camelCaseConvertion = true,
}: Request): Promise<T> {
  const tokens = getTokens();

  if (!tokens.accessToken && auth) {
    notifyLogout();
    handleLogout();
    throw new Error('Unauthorized');
  }

  if (auth && getTokenRemainingTime({ inMinutes: true }) < 1) {
    await refreshSession();
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(auth
        ? {
            Authorization: `Bearer ${tokens.accessToken}`,
          }
        : {}),
      ...options.headers,
    },
    ...options,
  };

  if (options.body) {
    requestOptions.body = auth
      ? JSON.stringify(options.body.valueOf())
      : qs.stringify(options.body.valueOf());
  }

  return fetch(url, requestOptions as RequestInit).then(async (response) => {
    if (!response.ok) {
      const error = new Error(response.statusText) as NetworkError;

      error.response = response;
      error.status = response.status;

      if (response.status === HttpStatusCodes.Unauthorized) {
        const refreshed = await refreshSession();

        if (refreshed)
          return request<T>({ url, options, auth, camelCaseConvertion });
      }

      throw error;
    }

    return parseJSON(response, camelCaseConvertion);
  });
}
