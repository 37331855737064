import { HTTP_METHOD } from '@gbm/snacks-types';

import request from '../utils/request';
import { config } from '../../config';

export function createModuleAPI(formData: {
  name: string;
  title: string;
  description: string;
}) {
  return request({
    url: `${config().CMS_API.v1}/modules`,
    options: { method: HTTP_METHOD.post, body: formData },
  });
}

export function deleteModule(name: string) {
  return request({
    url: `${config().CMS_API.v1}/modules/${name}`,
    options: { method: HTTP_METHOD.delete },
  });
}

export function getModule(name: string) {
  return request({
    url: `${config().CMS_API.v1}/modules/${name}`,
    options: { method: HTTP_METHOD.get },
  });
}

export function getModules() {
  return request({
    url: `${config().CMS_API.v1}/modules`,
    options: { method: HTTP_METHOD.get },
  });
}

export function updateModule(moduleData: {
  name: string;
  title: string;
  description: string;
  createdAt?: string;
  modifiedAt?: string;
}) {
  return request({
    url: `${config().CMS_API.v1}/modules/${moduleData.name}`,
    options: { method: HTTP_METHOD.put, body: moduleData },
  });
}
