import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { APP_ROUTES } from './routes';

import { AuthProvider } from './providers/auth';
import { GrantsProvider } from './providers/grants';

import { AppLayout } from './components/app-layout';
import { InactivityDetector } from './components/inactivity-detector';
import { Navigation } from './components/navigation';

import { ANNOUNCEMENTS_ROUTES } from './containers/announcements';
import { CATEGORIES_ROUTES } from './containers/categories';
import { FAQS_ROUTES } from './containers/faqs';
import { HELP_CENTER_ROUTES } from './containers/help-center';
import { MODULES_ROUTES } from './containers/modules';
import { NOT_FOUND_ROUTES } from './containers/not-found';
import { REM_ROUTES } from './containers/rules';
import { SUMMARY_ROUTES } from './containers/summary';
import { USERS_ROUTES } from './containers/users';

const router = createBrowserRouter([
  {
    path: APP_ROUTES.root,
    element: (
      <AuthProvider>
        <GrantsProvider>
          <InactivityDetector />
          <AppLayout>
            <Outlet />
          </AppLayout>
        </GrantsProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: APP_ROUTES.root,
        element: <Navigation />,
        children: [
          ANNOUNCEMENTS_ROUTES,
          CATEGORIES_ROUTES,
          FAQS_ROUTES,
          HELP_CENTER_ROUTES,
          MODULES_ROUTES,
          REM_ROUTES,
          SUMMARY_ROUTES,
          USERS_ROUTES,
        ],
      },
    ],
  },
  NOT_FOUND_ROUTES,
]);

export default function App() {
  return <RouterProvider router={router} />;
}
