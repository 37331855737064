import { APP_ROUTES } from '../../../../routes';

import { MenuItem } from '../menu-item';

import { Module } from '../../../../types/api/module';
import { User } from '../../../../types/api/user';

export function createMenuItems({
  modulesData,
  userData,
}: {
  modulesData?: Module[];
  userData?: User;
}) {
  if (!modulesData || !userData) return null;

  const modules = modulesData.map(({ name, title }) => ({
    name,
    title,
    to: (
      APP_ROUTES[name as keyof typeof APP_ROUTES] as {
        main: string;
      }
    )?.main,
  }));

  const menuItems = userData?.claims?.map(({ module }) => {
    const allowModule = modules?.find(({ name }) => name === module);

    if (!allowModule) return null;

    return (
      <MenuItem key={allowModule.name} to={allowModule.to}>
        {allowModule.title}
      </MenuItem>
    );
  });

  return menuItems?.filter(Boolean);
}
