import React, { useContext, useMemo } from 'react';

export function createContext<T = unknown>(
  defaultValue: T | undefined = undefined,
) {
  const ValueContext = React.createContext(defaultValue);

  type ProviderProps = {
    children: React.ReactNode;
    value?: T;
  };

  function Provider({ value, children }: ProviderProps) {
    const contextValue = useMemo(() => value ?? defaultValue, [value]);
    return (
      <ValueContext.Provider value={contextValue}>
        {children}
      </ValueContext.Provider>
    );
  }

  function useValue() {
    const contextValue = useContext(ValueContext);
    if (contextValue === undefined) {
      throw new Error(
        `To use notifications provider value, your component must be wrapper by it.`,
      );
    }
    return contextValue;
  }

  return [Provider, useValue] as const;
}
