import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

/**
 * https://sonner.emilkowal.ski/toaster
 */
const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      offset={32}
      richColors
      toastOptions={{
        classNames: {
          toast:
            'bg-blue-0 border-[1px] border-blue-50 rounded-2xl p-4 shadow-md',
          title: 'font-regular text-base',
          description: 'font-regular text-sm',
          closeButton: 'bg-blue-0 border-[1px] border-blue-50',
        },
      }}
      visibleToasts={5}
      {...props}
    />
  );
};

export { Toaster };
