import { useIntlTranslation } from '@gbm/snacks-i18n';
import { Typography } from '@gbm/starman-next/typography';

import { translations } from '../../../../translations';
import { useAuth } from '../../../providers/auth';
import { Button } from '../../../ui/components/button';

export function Logout() {
  const { t } = useIntlTranslation();
  const { logout } = useAuth();

  return (
    <Button className="w-full" onClick={logout}>
      <Typography variant="small">
        {t(translations.components.navigation.logout)}
      </Typography>
    </Button>
  );
}
