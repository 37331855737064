import { useQuery } from 'react-query';

import { getUserData } from '../../api/users';
import { User } from '../../types/api/user';

export function useUser(userEmail: string | undefined) {
  return useQuery<unknown, unknown, User>(['user', userEmail], () => {
    if (!userEmail) {
      throw new Error('User email is undefined');
    }
    return getUserData(userEmail);
  });
}
